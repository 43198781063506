@tailwind base;
@tailwind components;
@tailwind utilities; 

[aria-hidden="true"]:not(style):not(script) {
  aria-hidden: unset !important;
}

.App {
  min-width: 100%;
  min-height: 100vh;
}


main {
  background: white!important;
  padding-top: 3em!important;
  padding-bottom: 4em!important;
}

nav {
  display: flex;
  gap: 20px;
}

.header {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  height: 70px;
}

.headerLeft {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  font-weight: 700;
  font-size: 15px;
  height: 40px;
}

.menuOption {
  line-height: 40px;
}

.logo {
  height: 40px;
}

.content {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.firstColumn,
.secondColumn {
  width: 100%;
  min-height: 200px;
  margin-bottom: 20px;
}

.currentBalance {
  height: 60px;
  font-size: 40px;
  font-weight: 200;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  text-align: bottom;
}

.balanceOptions {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.extraOption {
  width: 180px;
  text-align: center;  
  border: 2px solid rgba(109, 109, 109, 0.246);
  border-radius: 1000px;
  font-weight: 600;
  color: rgba(50, 50, 50, 0.714);
  transition: 0.4s color, 0.4s background-color;
  padding: 6px;
  margin-bottom: 10px;
}

.extraOption:hover {
  cursor: pointer;
  background-color: #000000;
  color: white;
}

.requestAndPay {
  width: 100%; 
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.quickOption {
  background-color: #000000;
  height: 75px;
  width: 75px;
  border-radius: 10px;
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  position: relative;
  transition: all ease-in-out 200ms;
  margin-bottom: 10px;
}

.quickOption:hover {
  background-color: #07152f;
  transform: scale(1.2);
  transition: all ease-in-out 200ms;
  cursor: pointer;
}

.quickrequest {
  background-color: #1e7d2f!important;
}

.quickrequest:hover {
  background-color: #12b800!important;
}

.numReqs {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: red;
  height: 25px;
  width: 25px;
  border-radius: 100px;
  color: white;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}

.accountDetailRow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 70px;
  margin-left: 25px;
  gap: 30px;
}

.accountDetailHead {
  font-weight: 700;
  font-size: 16px;
  color: #4f4f4f;
}

.accountDetailBody {
  color: #767676;
  font-size: 12px;
  font-weight: 600;
}

/* Media Queries for Responsive Design */
@media (min-width: 768px) {
  .content {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
  }

  .firstColumn {
    width: 500px;
  }

  .secondColumn {
    width: 800px;
  }

  .currentBalance {
    font-size: 70px;
  }

  .header {
    padding-left: 100px;
    padding-right: 100px;
  }
}

/* Adjustments for smaller screens */
@media (max-width: 767px) {
  .header {
    flex-direction: column;
    height: auto;
    padding: 10px;
  }

  .headerLeft,
  .header-middle,
  .header-right {
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;
  }

  .accountDetailRow {
    flex-direction: column;
    height: auto;
    margin-left: 0;
    align-items: flex-start;
    padding: 10px;
  }

  .accountDetailHead,
  .accountDetailBody {
    text-align: left;
  }
}

/* Footer Styles 
.app-footer {
  background-color: #f8f8f8;  
  text-align: center;
  padding: 20px;
  font-size: 14px;
  position: relative;
  bottom: 0;
  height: auto;
  width: 100%;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-content p {
  margin: 0;
  font-weight: 600;
  color: #555;
}

.footer-links {
  margin-top: 10px;
  display: flex;
  gap: 20px;
}

.footer-links a {
  color: #555;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #000;
}
*/

/* Wallet Modal Styles */
.wallet-modal .ant-modal-content {
  background-color: #f0f0f0;
  border-radius: 10px;
}

.wallet-modal .ant-modal-header {
  background-color: transparent;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: 20px;
}

.wallet-modal .ant-modal-title {
  color: #000000;
}

.wallet-options {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 15px;
}

.wallet-option-button {
  width: 100%;
  height: 50px;
  background-color: #000 !important;
  color: #fff !important;
  border: none !important;
  border-radius: 5px !important;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s;
}

.wallet-option-button:hover:not(:disabled) {
  background-color: #333 !important;
}

.wallet-option-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Ensure error messages don't affect layout */
.ant-message {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
}