@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

.olsa-content {
  font-family: 'Inter', sans-serif;
  color: #ffffff;
  padding: 2rem;
  background-image: 
    linear-gradient(rgba(255, 255, 255, 0.05) 1px, transparent 1px),
    linear-gradient(90deg, rgba(255, 255, 255, 0.05) 1px, transparent 1px);
  background-size: 400px 400px;
  /* position: relative ;*/
  overflow: hidden;
  max-width: 650px;  
  height: 100%;  
  display: flex;
  justify-content: center;
  align-items: center;

}

.olsa-content::before {
  content: '';
  position: absolute;
  top: -50%;
  right: 40%;
  width: 800px;
  height: 1200px;
  background: radial-gradient(circle, rgba(115, 100, 219, 0.2) 0%, rgba(115, 100, 219, 0) 70%);
  z-index: 0;
  filter: blur(60px);
  overflow: hidden!important;
  max-width: 650px!important;

  display: flex;

}

.hero {
  text-align: center;
  margin-bottom: 4rem;
  position: relative;
  z-index: 1;
}

.hro::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 600px;
  height: 600px;
  background: radial-gradient(circle, rgba(115, 100, 219, 0.2) 0%, rgba(115, 100, 219, 0) 70%);
  z-index: 0;
  filter: blur(0px);
  overflow: hidden!important;
  max-width: 100vw;

  display: flex;

}

.beta-tag {
  display: inline-block;
  background-color: rgba(80, 80, 80, 0.1);
  color: gray;  
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
  margin-bottom: 2rem;
  
}

.star-icon {
  margin-right: 0.5rem;
}

.olsa-content h1 {
  font-size: 3.5rem;
  margin-bottom: 1rem;
  line-height: 1.2;
  font-weight: 700;
  color: black;
}

.hero p {
  font-size: 1.1rem;
  opacity: 0.7;
  max-width: 600px;
  margin: 0 auto 2rem;
  color: black!important;
}

.btn {
  padding: 0.5rem 1rem;
  border-radius: 25px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.btn-primary {
  background-color: #000000;
  color: #ffffff;
  border: none;
  transition: all 300ms ease-in-out;
}

.btn-primary:hover {
  background-color: #5c5c5c;
  color: #ffffff;
  border: none;
  
}

.btn-outline {
  background-color: transparent;
  color: #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.2);
  font-size: 0.9rem;
}

.btn-large {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
}

.arrow-icon {
  margin-left: 0.5rem;
}

.trust-indicator {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.trust-indicator span {
    color: rgb(5, 32, 207);
  }

.avatar-group {
  display: flex;
}

.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #1c1d24;
  border: 2px solid #0f1115;
  margin-left: -10px;
}

.features {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
}

.feature-card {
  background-color: #191b21;
  padding: 1.5rem;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
}

.feature-card h3 {
  margin-top: 0;
  font-size: 1.2rem;
  font-weight: 600;
}

.feature-card p {
  opacity: 0.7;
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

.feature-actions {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
}

.exchange-rates {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.rate {
  background-color: #1c1d24;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.currency {
  font-weight: 600;
}

.amount {
  flex-grow: 1;
  text-align: right;
  margin-right: 0.5rem;
}

.currency-code {
  opacity: 0.7;
}

.credit-card {
  position: relative;
}

.card-icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 40px;
  height: 40px;
  background-color: #1c1d24;
  border-radius: 8px;
}

.card-number {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .features {
    grid-template-columns: 1fr;
  }

  .olsa-content h1 {
    font-size: 2rem;
  }


.olsa-content::before {
    content: '';
    position: absolute;
    top: 0%;
    right: 0%;
    width: 100vw;
    height: 100vh;
    background: radial-gradient(circle, rgba(115, 100, 219, 0.2) 0%, rgba(115, 100, 219, 0) 70%);
    z-index: 0;
    filter: blur(60px);
    overflow: hidden!important;
    max-width: 100%!important;
    display: flex;
    z-index: 1;
  
  }


}
